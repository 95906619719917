import React, { useState } from "react";

const Accordion = ({ title, content, fontSize }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ width: "100%", marginBottom: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
            <div
                onClick={toggleAccordion}
                style={{
                    padding: "10px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: `${fontSize}`
                }}
            >
                {title}
            </div>
            {isOpen && (
                <div
                    id="accordion"
                    style={{
                        padding: "10px",
                        borderTop: "1px solid #ccc",
                    }}
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Accordion;
