import React from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./style/effects.css"

import "./style/main.css";
import Home from "./containers/home";
import Navbar from "./components/navbar";
import Features from "./containers/features";
import FAQs from "./containers/faqs";
import Footer from "./components/footer";
import BuilderTag from "./components/builderTag";
import "./style/responsive.css"
import Tokenomics from "./containers/tokenomics";

function App() {
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Infinity Bot | Home</title>
                    <meta
                        name="description"
                        content="Infinity Bot, is an innovative EVM-compatible BuyBot designed to elevate the trading experience for cryptocurrency enthusiasts and investors"
                    />

                    {/* Open Graph meta tags for social media previews */}

                </Helmet>

                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
                <Features />
                <Tokenomics />
                <FAQs />
                <Footer />
                <BuilderTag />
            </HelmetProvider>
        </React.Fragment>
    );
}

export default App;