import React from "react";
import { motion } from "framer-motion";

const BounceDiv = ({ children, key }) => {
    return (
        <motion.div key={key}
            initial={{ opacity: 0, x: -100 }} // Estado inicial (fuera de la pantalla a la izquierda)
            whileInView={{ opacity: 1, x: 0 }} // Cuando entra en vista (rebote hacia el centro)
            transition={{
                type: "spring", // Movimiento de resorte para rebote
                stiffness: 100,
                damping: 10,
            }}
            viewport={{ once: true, amount: 0.2 }} // Dispara solo una vez cuando el 20% está visible

        >
            {children}
        </motion.div>
    );
};

export default BounceDiv;
