import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const BlockchainEffect = () => {
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true);
        }
    }, []);

    const blocks = Array.from({ length: 15 }).map(() => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
    }));

    return (
        <div
            id="effect-block"
            style={{
                width: "calc(100% - 20%)",
                boxSizing: "border-box",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                pointerEvents: "none",
                zIndex: 99,
            }}
        >
            {/* Bloques animados */}
            {blocks.map((block, index) => (
                <motion.div
                    key={index}
                    initial={{
                        x: block.x,
                        y: block.y,
                    }}
                    animate={{
                        y: isSmall
                            ? block.y + Math.random() * 10 - 5
                            : block.y + Math.random() * 50 - 25, // Movimiento vertical
                        x: isSmall
                            ? block.x + Math.random() * 10 - 5
                            : block.x + Math.random() * 50 - 25, // Movimiento horizontal
                    }}
                    transition={{
                        duration: Math.random() * 4 + 2,
                        repeat: Infinity,
                        repeatType: "reverse",
                    }}
                    style={{
                        position: "absolute",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#2d8ecd",
                        border: "1px solid #2d8ecd",
                        borderRadius: "3px",
                        boxShadow: "0 0 5px rgba(39, 93, 174, 0.5)",
                    }}
                />
            ))}
        </div>
    );
};

export default BlockchainEffect;
