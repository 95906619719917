import React, { useEffect, useState } from "react"
import blocks from "../assets/images/blocks.png"
import "../style/features.css"
import TitleContainer from "../components/titleContainer"
import TextContainer from "../components/textContainer"
import BounceDiv from "../components/bounce"

const Features = () => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true)
        }
    }, [])

    const Features = [
        {
            index: "1.",
            title: "Advanced BuyBot",
            content: [
                {
                    text: "- Seamlessly tracks and executes trades across BSC, Ethereum, and PulseChain."
                },
                {
                    text: "- Supports multiple pairs, enabling users to monitor and interact with a wide range of tokens."
                }
            ]
        },
        {
            index: "2.",
            title: "Competitive Trading Utilities",
            content: [
                {
                    text: "- Sniper functions for instant trading opportunities."
                },
                {
                    text: "- AI-driven signals based on volume surges and trending tokens, providing users with actionable insights into the market’s hottest movers."
                },
                {
                    text: "- Customisable trading filters for private groups to identify and highlight top-performing tokens meeting specific criteria."
                }
            ]
        },
        {
            index: "3.",
            title: "Integrated Ad Space",
            content: [
                {
                    text: "- Projects can advertise directly within the bot interface."
                },
                {
                    text: "- Revenue from ad space feeds directly into the chart, boosting project visibility and supporting token performance."
                }
            ]
        }
    ]


    return (
        <React.Fragment>
            <section id="features" className="features-section">
                <div className="features-container">
                    <div className="features">
                        <div className="main-title-box">
                            <TitleContainer content={"Features"} fontSize={isSmall ? "20px" : "30px"} height={"50px"} classname="center-side" />
                        </div>
                        <div className="features-content">
                            <div className="content-box features-content-box">
                                {Features.map((item) => (
                                    <React.Fragment key={item.index}>
                                        <BounceDiv
                                            children={<React.Fragment key={item.index}>
                                                <div className="content-item-keys">
                                                    <TitleContainer height={"40px"} classname="left-side" fontSize={isSmall ? "13px" : "18px"} content={item.title} />
                                                    {item.content.map((content, index) => (
                                                        <React.Fragment key={index}>
                                                            <TextContainer fontSize={isSmall ? "13px" : "15px"} content={content.text} />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </React.Fragment>}
                                        />
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="features-image fadeIn">
                                <img src={blocks} alt="" className="img-features" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Features