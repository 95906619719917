import React, { useEffect, useState } from "react"
import TextContainer from "../components/textContainer";
import TitleContainer from "../components/titleContainer";
import "../style/home.css"
import ButtonRedirect from "../components/buttonRedirect";
import FallingStars from "../components/fallingStars";
import BlockchainEffect from "../components/blockchaineffect";

const Home = () => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true)
        }
    }, [])



    return (
        <React.Fragment>
            <section id="home" className="section-home">
                <BlockchainEffect />
                <div className="home-container ">
                    <div className="home-box">
                        <div className="home-content fadeIn">
                            <div className="home-title-box">
                                <TitleContainer fontSize={isSmall ? "20px" : "50px"} content={"What is Infinity Bot?"} classname={""} />
                            </div>

                            <div className="home-content-box">
                                <TextContainer classname={""} fontSize={isSmall ? "13px" : "30px"} content={"Infinity Bot is an innovative EVM-compatible BuyBot designed to elevate the trading experience for cryptocurrency enthusiasts and investors. Built with versatility and efficiency in mind, this bot is more than just a tool, it’s a comprehensive ecosystem for projects , communities, and traders alike."} />
                            </div>

                            <div className="home-button-box">
                                <ButtonRedirect fontSize={isSmall ? "14px" : "15px"} color={"#2d8ecd"} link={"https://t.me/Infinity_buybot"} content={"Try Bot"} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Home;