import React, { useEffect, useState } from "react"
import TitleContainer from "../components/titleContainer"
import tkns from "../assets/images/graph.png"
import "../style/tokenomics.css"
import TextContainer from "../components/textContainer"

const Tokenomics = () => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true)
        }
    }, [])


    const tokenomics = [
        {
            content: "- 90% of tokens added to liquidity."
        },
        {
            content: "- 6% added to IFB/SKOL liquidity."
        },
        {
            content: "- 2% for marketing, development and server maintenance."
        },
        {
            content: "- 2% locked for CEX listings"
        },
    ]

    const note = [
        {
            note: "Note: 1% of every token purchase / sale will be burned, thus reducing the total amount in circulation, making the token deflationary."
        },
    ]

    return (
        <React.Fragment>
            <section id="tokenomics" className="tokenomics-section">
                <div className="tokenomics-container">
                    <div className="tokenomics-box">
                        <div className="container tokenomics">
                            <div className="title-box tokenomics">
                                <TitleContainer classname="center-side" fontSize={isSmall ? "20px" : "40px"} content={"Tokenomics"} />
                            </div>
                            <div className="tokenomics-content-box">
                                <div className="img-container-tkns">
                                    <img src={tkns} alt="" className="tnks-img" />
                                </div>

                                <div className="tkns-content-text">
                                    {tokenomics.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <TextContainer fontSize={isSmall ? "14px" : "18px"} content={item.content} />
                                        </React.Fragment>
                                    ))}
                                    {note.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <TextContainer fontSize={isSmall ? "14px" : "18px"} content={item.note} />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Tokenomics