import React from "react"
import "../style/buttonRedirect.css"
import icon from "../assets/icons/right.png"


const ButtonRedirect = ({ content, link, color, fontSize }) => {
    return (
        <React.Fragment>
            <div className="button-container">
                <div style={{
                    backgroundColor: `${color}`
                }} className="button-box">
                    <div className="button-content">
                        <a
                            style={{
                                fontSize: `${fontSize}`
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${link}`} className="button-link">
                            {content}
                            <div className="icon-box">
                                <img src={icon} alt="icon-right" className="icon-to" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ButtonRedirect