import React from "react"
import "../style/titlecontainer.css"

const TitleContainer = ({ content, classname = "", fontSize, height }) => {
    return (
        <React.Fragment>
            <div style={
                {
                    height: `${height}`
                }
            } className="title-container">
                <div className="title-box">
                    <div style={{
                        fontSize: `${fontSize}`,
                    }} className={`title box ${classname}`}>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TitleContainer