import React from "react"
import "../style/textcontainer.css"


const TextContainer = ({ content, classname, fontSize }) => {
    return (
        <React.Fragment>
            <div className="text-container">
                <div className="text-box">
                    <div style={{
                        fontSize: `${fontSize}`
                    }} className={`text box ${classname}`}>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TextContainer