import React, { useState } from "react"
import logo from "../assets/images/logo.png"
import menu from "../assets/icons/menu.png"
import "../style/navbar.css"

const Navbar = () => {

    const [showMenu, setShowMenu] = useState(false);

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleShowMenu = (option) => {
        setShowMenu(option);
    };

    return (
        <React.Fragment>
            <nav className="navbar">
                <div className="navbar-container">
                    <div className="navbar-logo">
                        <div className="brand-logo-box">
                            <img src={logo} alt="logo" className="brand-logo" />
                        </div>
                        <div className="brand-title">
                            <div className="title-navbar">Infinity Bot </div>
                        </div>
                    </div>
                    <div className={`navbar-box `}>
                        <div className={`container-nav ${showMenu ? "active" : "inactive"}`}>
                            <ul className={`navbar-ul ${showMenu ? "ul-active" : "ul-inactive"}`}>
                                <li onClick={() => handleScroll("home")} className="navbar-li home">
                                    Home
                                </li>
                                <li onClick={() => handleScroll("features")} className="navbar-li features">
                                    Features
                                </li>
                                <li onClick={() => handleScroll("tokenomics")} className="navbar-li tokenomics">
                                    Tokenomics
                                </li>
                                <li onClick={() => handleScroll("FAQs")} className="navbar-li faqs">
                                    Faqs
                                </li>
                                <li className="navbar-li support">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://t.me/Mrforrestgump84"
                                        className="links support-link">
                                        Support
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div onClick={() => handleShowMenu(true)} className="navbar-box-rs">
                            <div className="navbar-box-rs-icon">
                                <img src={menu} alt="" className="navbar-rs-icon" />
                            </div>
                        </div>
                        <div
                            onClick={() => handleShowMenu(false)}
                            className={`menu-rs-box close ${showMenu ? "btn-active" : "btn-inactive"
                                }`}
                        >
                            <div className="icon-rs-menu close">
                                <div className="x-close">X</div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Navbar