import React, { useEffect, useState } from "react"
import TitleContainer from "./titleContainer"
import "../style/footer.css"

const Footer = () => {

    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true)
        }
    }, [])

    const footer = [
        {
            title: "Home",
            content: [
                {
                    title: "What is Infinity Bot?",
                    to: "home",
                    type: "internal"
                },
                {
                    title: "Features",
                    to: "features",
                    type: "internal"

                },
                {
                    title: "Tokenomics",
                    to: "tokenomics",
                    type: "internal"

                },
                {
                    title: "FAQs",
                    to: "faqs",
                    type: "internal"

                },
            ]
        },
        {
            title: "Socials",
            content: [
                {
                    title: "Twitter",
                    to: "https://x.com/infinitybot_bsc",
                    type: "external"

                },
                {
                    title: "Telegram",
                    to: "https://t.me/InfinityBotChat",
                    type: "external"
                },
            ]
        },
    ]

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <React.Fragment>
            <div className="footer-container">
                <div className="footer-box">
                    <div className="footer-content">
                        {
                            footer.map((item, index) => (
                                <div key={index} className="footer-content-box">
                                    <div className="footer-title">
                                        <TitleContainer height={isSmall ? "14px" : "20px"} classname="left-side" content={item.title} />
                                    </div>
                                    <div className="footer-media-content">
                                        {
                                            item.content.map((content, index) => (
                                                content.type == "internal" ? (
                                                    <div onClick={() => handleScroll(content.to)} key={index} className="footer-links">
                                                        {content.title}
                                                    </div>
                                                ) : (
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={content.to}
                                                        className="links footer-content-media">{content.title}</a>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Footer