import React, { useEffect, useState } from "react"
import Accordion from "../components/accordion"
import TitleContainer from "../components/titleContainer"
import "../style/faqs.css"
import TextContainer from "../components/textContainer"

const FAQs = () => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsSmall(true)
        }
    }, [])


    const Faqs = [
        {
            title: "What is $IFB?",
            content: "$IFB is the native token for infinity bot It's purpose is to reward holders for holding $IFB, We plan to put around 90% of ad revenue generated directly into $IFB's chart"
        },
        {
            title: "What are $IFB's taxes for?",
            content: [
                {
                    content: "We have taxes set at 4/4"
                },
                {
                    content: "These are where our taxes go:"
                },
                {
                    content: " - 1% goes to $IFB LP"
                },
                {
                    content: " - 1% burned"
                },
                {
                    content: " - 1% will be sent to build the $IFB dao"
                },
                {
                    content: " - 1% will be sent to a reflections wallet to be claimed by the user every 7 days "
                }
            ],

        },
        {
            title: "What is a Comp Bot?",
            content: "A comp bot refers to the bot itself, but it refers to the modality for conducting trading competitions of a token on blockchains with EVM support."
        },
        {
            title: "Do I have to pay to use the bot?",
            content: "No our bot is completely free! The only things that require payments will be for trending or for paid promotion within our bot!"
        },
        {
            title: "Why did we build our bot?",
            content: `We built our bot because we can see an obvious space in the market for a better and cheaper version! We plan to become the "people's bot" where trending or promotion are cheap enough that any project can use it big or small!`
        },
        {
            title: "How does our bot work?",
            content: "Our bot is written in Golang with custom built variables and tracking algorithms that pull blockchain information directly from it's source it then uses this to monitor the users chosen pair or pairs!"
        }
    ]


    return (
        <React.Fragment>
            <section id="faqs" className="faqs-section">
                <div className="faqs-container">
                    <div className="faqs-box">
                        <div className="faqs-title-box">
                            <TitleContainer classname="center-side" fontSize={isSmall ? "20px" : "50px"} content={"Frequently Asked Questions"} />
                        </div>
                        <div className="faqs-content-box">
                            <div className="faqs-content">
                                {Faqs.map((item, index) => (
                                    <Accordion
                                        fontSize={isSmall ? "14px" : "20px"}
                                        key={index}
                                        title={item.title}
                                        content={
                                            Array.isArray(item.content)
                                                ? item.content.map((content, i) => (
                                                    <TextContainer key={i} content={content.content} />
                                                ))
                                                : item.content
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default FAQs