import React from "react"
import builder from "../assets/icons/hammer.png"
import "../style/builder.css"


const BuilderTag = () => {
    return (
        <React.Fragment>
            <div className="builder-tag-container">
                <div className="builder-tag-content">
                    <div className="bulder-tag-copyright">
                        © 2024 Infinity Bot | All rights reserved |
                    </div>
                    <div className="builder-tag">
                        <div className="builder-img">
                            <img src={builder} alt="" className="builder-tag" />
                        </div>
                        <div className="bulder-tag">
                            Built by Polarys Foundation
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BuilderTag